import React from "react"
import { Link } from "gatsby"
import Modal from "react-modal"

import ReactDOM from "react-dom"

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "31",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const ModalAjuda = ({ isShowing, hide }) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            isOpen={isShowing}
            onRequestClose={hide}
            contentLabel="Suporte SH2"
            style={customStyles}
            ariaHideApp={false}
          >
            <div className="modal-content">
              <div className="box">
                <h1 className="title is-4 is-uppercase has-text-dark">
                  Precisando de ajuda?
                </h1>
                <hr />
                <div className="columns modal-ajuda perguntas">
                  <div className="column">
                    <ul>
                      <li>
                        <a href="/sac/index.php?rp=/knowledgebase/44/Redirecionamento-de-Emails.html">
                          Redirecionamento de E-mails
                        </a>
                      </li>
                      <li>
                        <a href="/sac/index.php?rp=/knowledgebase/2/Email">
                          Configurar meu e-mail no computador
                        </a>
                      </li>
                      <li>
                        <a href="/sac/index.php?rp=/knowledgebase/43/Resposta-Automatica-para-e-mail.html">
                          Criar resposta automática de e-mail
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="column">
                    <ul>
                      <li>
                        <a href="/sac/index.php?rp=/knowledgebase/29/Criando-senhas-fortes.html">
                          Criar uma senha segura
                        </a>
                      </li>
                      <li>
                        <a href="/sac/index.php?rp=/knowledgebase/6/FTP">
                          Configurar acesso FTP
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className="columns p-l-sm">
                  <div className="colum p-xs">
                    <a
                      href="/sac/index.php?rp=/knowledgebase"
                      className="button is-warning"
                    >
                      Ver outras dúvidas frequentes
                    </a>
                  </div>
                  <div className="colum p-xs">
                    <a href="/sac/submitticket.php" className="button is-link">
                      Abrir ticket de suporte
                    </a>
                  </div>
                  <div className="colum p-xs">
                    <Link to="/contato" className="button is-dark">
                      Contato
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null

export default ModalAjuda
