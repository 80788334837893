import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import ModalAjuda from "./ModalAjuda"
import useModal from "./utils/useModal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"

const Navbar = ({ navActive }) => {
  const { isShowing, toggle } = useModal()
  const [isMenu, setMenu] = useState(false)

  function toggleMenu() {
    setMenu(!isMenu)
  }

  return (
    <>
      <nav className="navbar is-uppercase">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img
                className="is-logo"
                src="/assets/images/logo_mini.png"
                alt="Logo"
              />
            </Link>
            <button
              className={`navbar-burger burger ${isMenu ? "is-active" : ""}`}
              data-target="navbarMenuHeroA"
              onClick={toggleMenu}
              aria-label="Menu Principal"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div
            id="navbarMenuHeroA"
            className={`navbar-menu  ${isMenu ? "is-active" : ""}`}
          >
            <div className="navbar-start">
              <button
                className={`navbar-item ajuda ${
                  navActive === "ajuda" ? "is-active" : ""
                }`}
                onClick={toggle}
              >
                Precisando de ajuda?
              </button>

              <a className="navbar-item cliente" href="/sac/clientarea.php">
                <span>Central do Cliente</span>
                <FontAwesomeIcon
                  icon={faLock}
                  className="icon"
                  transform="shrink-4 right-4"
                />
              </a>
            </div>
            <div className="navbar-end">
              <Link
                className={`navbar-item hospedagem ${
                  navActive === "hospedagem" ? "is-active" : ""
                }`}
                to="/hospedagem"
              >
                Hospedagem
              </Link>
              <Link
                className={`navbar-item loja ${
                  navActive === "loja" ? "is-active" : ""
                }`}
                to="/loja"
              >
                Loja Virtual
              </Link>
              <Link
                className={`navbar-item website ${
                  navActive === "website" ? "is-active" : ""
                }`}
                to="/site"
              >
                Websites
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <ModalAjuda isShowing={isShowing} hide={toggle} />
    </>
  )
}

Navbar.propTypes = {
  navActive: PropTypes.string,
}

export default Navbar
