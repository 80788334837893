import React from "react"

import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons"

const Footer = () => {
  return (
    <>
      <section
        id="footerContato"
        className="hero is-medium is-warning is-bold p-t-lg p-b-lg"
      >
        <div className="container">
          <div className="block">
            <div className="columns">
              <div className="column has-text-centered">
                <h1 className="title is-4">
                  <FontAwesomeIcon
                    icon={faPhoneSquareAlt}
                    className="icon fa-fw"
                  />
                  Horário de Atendimento
                </h1>
                <h2 className="subtitle is-size-6">
                  Segunda a sexta das 8:30h as 18h
                </h2>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <h1 className="title is-5">(19) 3631-3620</h1>
                <h2 className="subtitle is-size-6">São João da Boa Vista</h2>
              </div>
              <div className="column">
                <h1 className="title is-5">(19) 3195-9555</h1>
                <h2 className="subtitle is-size-6">São João da Boa Vista</h2>
              </div>
              <div className="column">
                <h1 className="title is-5">(11) 3508-8717</h1>
                <h2 className="subtitle is-size-6">São Paulo</h2>
              </div>
              <div className="column">
                <h1 className="title is-5">(21) 3514-7307</h1>
                <h2 className="subtitle is-size-6">Rio de Janeiro</h2>
              </div>
              <div className="column">
                <h1 className="title is-5">(31) 3515-5977</h1>
                <h2 className="subtitle is-size-6">Belo Horizonte</h2>
              </div>
              <div className="column">
                <h1 className="title is-5">(61) 3246-0820</h1>
                <h2 className="subtitle is-size-6">Brasília</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer has-text-white">
        <section className="container">
          <div className="block">
            <div className="columns">
              <div className="column">
                <h1 className="title is-size-6 has-text-light is-uppercase">
                  Atendimento
                </h1>
                <ul>
                  <li>
                    <a href="/sac/clientarea.php">Central do Cliente</a>
                  </li>
                  <li>
                    <a href="/sac/index.php?rp=/knowledgebase">
                      Base de Conhecimento
                    </a>
                  </li>
                  <li>
                    <a href="/sac/submitticket.php">Abrir um Ticket</a>
                  </li>
                  <li>
                    <Link to="/suporte">Suporte</Link>
                  </li>
                  <li>
                    <Link to="/contato">Contato</Link>
                  </li>
                </ul>
              </div>
              <div className="column">
                <h1 className="title is-size-6 has-text-light is-uppercase">
                  Serviços
                </h1>
                <ul>
                  <li>
                    <Link to="/hospedagem">
                      Hospedagem de Sites e <br />
                      E-mail Profissional
                    </Link>
                  </li>
                  <li>
                    <Link to="/site#maisServicos">Registro de Domínio</Link>
                  </li>
                </ul>
              </div>
              <div className="column">
                <h1 className="title is-size-6 has-text-light is-uppercase">
                  Soluções Web
                </h1>
                <ul>
                  <li>
                    <Link to="/loja">Criar Loja Virtual</Link>
                  </li>
                  <li>
                    <Link to="/site">Criar Website</Link>
                  </li>
                  <li>
                    <Link to="/site#maisServicos">E-mail Marketing</Link>
                  </li>
                  <li>
                    <Link to="/site#maisServicos">Certificado Digital SSL</Link>
                  </li>
                </ul>
              </div>

              <div className="column">
                <p>
                  <img
                    className="is-logo"
                    src="assets/images/logo_mini.png"
                    alt="Logo"
                  />
                  <br />
                  <strong>SH2 Soluções WEB</strong>, Hospedagem de Sites e
                  E-mail Profissional.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="block has-text-centered">
            <p className="is-size-7">
              Todos os direitos reservados desde 2004 ©
            </p>
          </div>
        </section>
      </footer>
    </>
  )
}

export default Footer
